import { Main, MainContent } from "../../common/elements";
import "./Atlas.css";

import atlas from "../../resources/feed/Light_AP.svg";

export default function Atlas() {
  return (
    <Main>
      <MainContent>
        <div className="Atlas-logo">
          <img src={atlas} alt="Profile of Min Jun Sung"></img>
        </div>
      </MainContent>

      <MainContent>
        <div className="Atlas-title">
          <a href="https://atlas.themrsung.com/" className="page-title" target="_blank" rel="noreferrer">
            Atlas Partners
          </a>
          <p className="page-subtitle">Atlas Partners website introduction.</p>
        </div>
      </MainContent>
    </Main>
  );
}
