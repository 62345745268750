import "./App.css";

import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "./pages/landing/Landing";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import Resume from "./pages/resume/Resume";
import Atlas from "./pages/atlas/Atlas";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" Component={Landing} />
        <Route path="/resume" Component={Resume} />
        <Route path="/atlas" Component={Atlas} />

        <Route path="*" Component={PageNotFound} />
      </Routes>
    </div>
  );
}

export default App;
