import "./PageNotFound.css";
import "../../common/skeleton.css";

import { Main, MainContent } from "../../common/elements";

import pageNotFound from "../../resources/images/page_not_found.svg";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <Main>
      <MainContent>
        <div className="PageNotFound-image">
          <img src={pageNotFound} alt="Page not Found"></img>
        </div>
      </MainContent>
      <MainContent>
        <div className="PageNotFound-title">
          <h1 className="page-title">Page not Found</h1>
          <p className="page-subtitle">Error 404. Please check the URL.</p>
        </div>
      </MainContent>
      <MainContent>
        <div
          className="PageNotFound-home"
          onClick={() => {
            navigate("/");
          }}
        >
          <p className="PageNotFound-backToHome">Back to Home</p>
        </div>
      </MainContent>
    </Main>
  );
}
