import "./Resume.css";
import "../../common/skeleton.css";
import { Main, MainContent } from "../../common/elements";

export default function Resume() {
  return (
    <Main>
      <MainContent>
        <div className="Resume-title">
          <h1 className="page-title">Resumé</h1>
          <p className="page-subtitle">This is my resumé.</p>
        </div>
      </MainContent>
    </Main>
  );
}
